<template>
  <div class="about">
    <el-tabs v-model="activeName">
      <el-tab-pane label="师傅反馈列表" name="1">
        <Withdrawal :nowData="rowData"></Withdrawal>
      </el-tab-pane>
      <el-tab-pane label="用户反馈列表" name="2">
        <Journal :nowData="rowData"></Journal>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
// @ is an alias to /src
import Withdrawal from '../../components/feedbackChild/Withdrawal'
import Journal from '../../components/feedbackChild/Journal'
export default {
  name: 'brand',
  components:{
    Withdrawal,
    Journal,
  },
  data(){
    return{
      activeName:'1',
      login:false,
      rowData:{
        worker_id:'',
      }

    }
  },
  created() {

  },
  methods:{

  }
}
</script>
<style lang="less" >

</style>